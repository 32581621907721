.header {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 15px 0;
  &__logo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }
  &__logo {
    border-radius: 50%;
    width: 70px;
  }
  &__logo-text {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    span {
      font-size: 14px;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    &__links {
      display: flex;
      gap: 20px;
    }
    &__link {
      color: #fff;
      transition: 0.3s;
    }
    &__item {
      position: relative;
      &:hover {
        .nav__link {
          color: #58998b;
          transition: 0.3s;
        }
        &::after {
          display: block;
          transition: 0.3s;
          bottom: -5px;
          background-color: #58998b;
        }
      }
      &::after {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        transition: 0.3s;
      }
    }
  }
  .burger {
    width: 30px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s;
    display: none;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: #fff;
      left: 0;
      width: inherit;
      height: 2px;
    }
    &::before {
      transition: 0.3s;
      top: 0px;
    }
    &::after {
      transition: 0.3s;
      top: 20px;
    }
    &__item {
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: #fff;
      position: absolute;
      transition: 0.3s;
      top: 10px;
    }
  }
  .burger.active {
    position: absolute;
    right: 20px;
    top: 40px;
    &::before {
      transform: rotate(-45deg);
      top: 10px;
      left: 0;
      transition: 0.3s;
    }
    &::after {
      transform: rotate(45deg);
      top: 10px;
      transition: 0.3s;
      left: 0;
    }
    .burger__item {
      transition: 0.3s;
      display: none;
    }
  }
  &.active {
    -webkit-box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.75);
    position: relative;
    .header__logo-text,
    .nav__link {
      color: #161616;
    }
    .burger {
      &__item,
      &::before,
      &::after {
        background-color: #161616;
      }
    }
  }
}

@media (max-width: 1300px) {
  .header {
    &__logo-text {
      width: 300px;
    }
    .nav {
      &__links {
        gap: 10px;
      }
      &__link {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .header {
    .burger {
      display: block;
    }
    .nav {
      &__links {
        flex-direction: column;
        background-color: #000000de;
        padding: 70px 30px 30px 30px;
        width: 300px;
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        gap: 20px;
        &.active {
          display: flex;
        }
      }
    }
    &.active {
      .nav__link {
        color: #fff;
      }
      .burger.active {
        &__item,
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}

@media (max-width: 460px) {
  .header {
    &__logo-text {
      display: none;
    }
  }
}
