.footer {
  padding: 30px 0;
  background-color: #1d1d1d;
  color: #a9a9a9;
  &__text {
    text-align: center;
    font-size: 12px;
  }
  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
  }
  &__socials {
    display: flex;
    gap: 15px;
  }
  a {
    &:hover {
      color: #fff;
    }
  }

  &__social {
    a {
      font-size: 30px;
    }
  }
}
